export const Email = {
  ADMIN: {
    EMAIL: 'teddmegadd@gmail.com',
    PASSWORD: `aezwyvkcwheoyfam`,
    HOST: 'smtp.gmail.com',
    PORT: 465,
    SECURE: true,
  },
  MESSAGE: {
    REGISTRATION: {
      SUBJECT: `Tedd+Add: Új regisztráció a weben`,
      HTML: `Új regisztráció a weben keresztül. <br><br>
      <b>Email:</b> {email} <br>
      <b>Név:</b> {displayName} <br><br>
      Az alábbi gombra kattintva lehet a szervezetekhez adni: <br>
      <a href="https://admin.teddmegadd.hu/admin/table-list">
        <button> Szervezetekhez ad</button>
      </a>
      `,
    },
    NEW_EVENT_CREATED: {
      SUBJECT: `Tedd+Add: Új esemény elkészült`,
      HTML: `Új esemény elkészült <br><br>
      <b>Esemény neve:</b> {eventName} <br>
      <b>Szervezet:</b> {organization} <br><br>
      Az alábbi gombra kattintva lehet a jóváhagyni az eseményt <br>
      <a href="https://admin.teddmegadd.hu/admin/event">
        <button>Esemény jóváhagyás</button>
      </a>
      `,
    },
    DONATION_ENOUGH: {
      SUBJECT: `Tedd+Add: Sikeres adományozás`,
      HTML: `Sikeres adományozás <br><br>
      <b>Adományozás neve:</b> {donationName} <br>
      <b>Szervezet:</b> {donatedOrganization} <br><br>
      Az alábbi gombra kattintva lehet megtekinteni az adományozást <br>
      <a href="https://admin.teddmegadd.hu/admin/donation">
        <button>Megtekintés</button>
      </a>
      `,
    },
    GO_TO_EVENT: {
      SUBJECT: `Tedd+Add: Önkéntes eseményre jelentkeztél`,
      HTML: `Kedves {attendeeName}!<br><br>
        Köszönjük, hogy jelentkeztél erre az önkéntes eseményre.<br><br>
        <b>Esemény:</b> {eventName} <br>
        <b>Időpont :</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
        <b>Helyszín :</b> {eventCity}, {eventAddress} <br>
        <b>Szervező :</b> {eventOrganizer} <br><br>

        Jelentkezésedet rögzítettük, és értesítettük erről az esemény szervezőjét.<br>
        Kérünk, hogy ha eddig nem tetted volna, írd be a naptáradba az eseményt, mert számítanak Rád!<br><br>

        Üdvözlettel: Tedd+Add csapata`,
    },
    APPLICATION_TO_EVENT_ORGANIZATION: {
      SUBJECT: `Tedd+Add: Jelentkezés történt`,
      HTML: `
      Tisztelt {eventOrganizer}!<br><br>
        Örömmel értesítjük, hogy a Tedd+Add városépítő applikációban jelentkezés történt az alábbi eseményre.<br><br>
        <b>Esemény:</b> {eventName} <br>
        <b>Esemény időpontja:</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
        <b>Helyszín:</b> {eventCity}, {eventAddress} <br>
        <b>Jelentkező:</b> {attendeeName} <br>
        <b>Jelentkező e-mail címe:</b> {attendeeEmail} <br>
        <b>Helyek száma :</b> {currentNumberOfAttendees} / {maxNumberOfAttendees} <br><br>
        Az alábbi gombra kattintva tudja megtekinteni az eseményt: <br>
        <a href="https://admin.teddmegadd.hu/admin/event">
          <button>Esemény kezelése</button>
        </a><br><br>
        Munkájához további sok sikert kívánunk!<br>
        Tedd+Add csapata
      `,
    },
    CANCELED_TO_EVENT: {
      SUBJECT: `Tedd+Add: Önkéntes eseményre való jelentkezés törlése`,
      HTML: `
      Kedves {attendeeName}!<br><br>
      Jelentkezésed az alábbi eseményről törölték.<br><br>
      <b>Esemény:</b> {eventName} <br>
      <b>Időpont :</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
      <b>Helyszín :</b> {eventCity}, {eventAddress} <br>
      <b>Szervező :</b> {eventOrganizer} <br><br>
      Kérünk, hogy a naptáradból is töröld az eseményt.<br>
      Várunk egy következő önkéntes feladatra, nézd meg most az applikációban, hogy milyen lehetőségek vannak!<br><br>
      Üdvözlettel: <br>
      Tedd+Add csapata
      `,
    },
    CANCELED_TO_EVENT_ORGANIZATION: {
      SUBJECT: `Tedd+Add: Jelentkezés törölve lett`,
      HTML: `
      Tisztelt {eventOrganizer}!<br><br>
      Az alábbi eseményről töröltek egy részvételt. <br><br>
      <b>Esemény:</b> {eventName} <br>
      <b>Esemény időpontja:</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
      <b>Helyszín:</b> {eventCity}, {eventAddress} <br>
      <b>A résztvevő neve, aki törölve lett:</b> {attendeeName} <br>
      <b>A résztvevő e-mail címe:</b> {attendeeEmail} <br>
      <b>Helyek száma :</b> {currentNumberOfAttendees} / {maxNumberOfAttendees} <br><br>
      Az alábbi gombra kattintva tudja megtekinteni az eseményt:<br>
      <a href="https://admin.teddmegadd.hu/admin/event">
        <button>Esemény kezelése</button>
      </a><br><br>
      Munkájához további sok sikert kívánunk!<br>
      Tedd+Add csapata
      `,
    },
    DELETED_EVENT: {
      SUBJECT: `Tedd+Add: Esemény törölve lett`,
      HTML: `
      Kedves {attendeeName}!<br><br>
      Az alábbi eseményt törölték. <br><br>
      <b>Esemény:</b> {eventName} <br>
      <b>Időpont :</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
      <b>Helyszín :</b> {eventCity}, {eventAddress} <br>
      <b>Szervező :</b> {eventOrganizer} <br>
      <b>A törlés oka:</b> {reason} <br><br>
      Kérünk, hogy a naptáradból is töröld az eseményt.<br>
      Várunk egy következő önkéntes feladatra, nézd meg most az applikációban, hogy milyen lehetőségek vannak!<br><br>
      Üdvözlettel: <br>
      Tedd+Add csapata
      `,
    },
    DELETED_EVENT_ORGANIZATION: {
      SUBJECT: `Tedd+Add: Esemény törölve lett`,
      HTML: `
      Tisztelt {eventOrganizer}!<br><br>
      Az alábbi esemény törölve lett.<br>
      <b>Esemény:</b> {eventName} <br>
      <b>Időpont :</b> {eventDateTimeFrom} - {eventDateTimeTo} <br>
      <b>Helyszín :</b> {eventCity}, {eventAddress} <br>
      <b>A törlés oka:</b> {reason} <br><br>
      Bízunk benne, hogy hamarosan új önkéntes feladatot hirdet meg a Tedd+Add adminisztrációs felületén.<br><br>
      Munkájához további sok sikert
      kívánunk!<br>
      Tedd+Add csapata
      `,
    },
  },
}

export const EmailTransport = {
  DEFAULT: {
    host: Email.ADMIN.HOST,
    port: Email.ADMIN.PORT,
    secure: Email.ADMIN.SECURE,
    auth: {
      user: Email.ADMIN.EMAIL,
      pass: Email.ADMIN.PASSWORD,
    },
  },
}
