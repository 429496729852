// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD7Go1s197vdlsBMmqcL96Podkcl58-mFs',
    authDomain: 'kempire-develop-dea05.firebaseapp.com',
    databaseURL:
      'https://kempire-develop-dea05-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'kempire-develop-dea05',
    storageBucket: 'kempire-develop-dea05.appspot.com',
    messagingSenderId: '718675541827',
    appId: '1:718675541827:web:cdfc7d13bafb343f8b79f6',
    measurementId: 'G-HDN1PCY4K3',
  },
}
