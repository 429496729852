import { Component, OnInit } from '@angular/core';
import '../../../assets/js/stylish-portfolio/stylish-portfolio.min.js'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public googleFromLink = 'https://forms.gle/x9sJpkUKyoA16UvK9'
  public readonly appStoreLink = '#'
  public readonly playStoreLink = '#'
  constructor() { }

  ngOnInit(): void {
  }
}
