import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppUser} from 'app/models/app-user.model';
import {UserService} from 'app/services/user.service';

@Component({
  selector: 'app-user-delete-confirmation',
  templateUrl: './user-delete-confirmation.component.html',
  styleUrls: ['./user-delete-confirmation.component.css'],
})
export class UserDeleteConfirmationComponent implements OnInit {
  user: AppUser;
  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<UserDeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if (data && data.user) {
      this.initUserModel(data.user);
    }
  }

  private initUserModel(user: AppUser) {
    if (user) {
      this.user = user;
      return;
    }
  }

  ngOnInit(): void {}
  confirm(): void {
    this.userService.deleteUser(this.user.id);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
