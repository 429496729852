import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from 'app/services/user.service'
import { RouteNames } from 'app/const/route/route-names.model'
import { AuthService } from 'app/services/auth.service'
import { Subscription } from 'rxjs'
import * as firebase from 'firebase'
import { EmailService } from 'app/services/email.service'
import { Email } from '../../../../shared/const/email/email.const'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = []
  TABS = {
    LOGIN: 0,
    REGISTRATION: 1,
  }
  currentTabIndex = 0
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private emailService: EmailService,
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.userLoggedinEvent.subscribe((isLoggedIn) => {
        isLoggedIn
          ? this.router.navigate(['/' + RouteNames.PATH_EVENT])
          : this.router.navigate(['/' + RouteNames.PATH_LOGIN])
      }),
    )
  }

  onLogin(event) {
    this.userService.save(event)
    if (this.currentTabIndex === this.TABS.REGISTRATION) {
      this.emailService.send(
        Email.ADMIN.EMAIL,
        Email.ADMIN.EMAIL,
        Email.MESSAGE.REGISTRATION.SUBJECT,
        this.getRegistrationEmailWithParams(event.email, event.displayName),
      )
    }
  }

  getRegistrationEmailWithParams(email: string, displayName: string): string {
    return Email.MESSAGE.REGISTRATION.HTML.replace('{email}', email).replace('{displayName}', displayName)
  }

  signInGoogle() {
    this.authService.signInWithGoogle()
  }

  onError(event) {
    console.error(event)
  }

  tabChanged(event) {
    this.currentTabIndex = event.index
  }
}
