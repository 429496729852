import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { Routes, RouterModule } from '@angular/router'
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { AuthGuardService } from './services/guards/auth-guard.service'
import { RouteNames } from './const/route/route-names.model'
import { LoginComponent } from './components/login/login.component'
import { HomeComponent } from './components/home/home.component'
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component'
import { TemporaryFrontPageComponent } from './components/temporary-front-page/temporary-front-page.component'

const routes: Routes = [
  {
    path: RouteNames.PATH_HOME,
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: RouteNames.PATH_TEMPORARY_FRONT_PAGE,
    component: TemporaryFrontPageComponent,
    canActivate: [AuthGuardService],
  },
  { path: RouteNames.PATH_LOGIN, component: LoginComponent },
  {
    path: RouteNames.PATH_PRIVACY_POLICY,
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(mod => mod.AdminLayoutModule),
      },
    ],
  },
  { path: '**', component: LoginComponent },
]

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
