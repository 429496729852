import { Component } from '@angular/core'
import { loadCldr } from '@syncfusion/ej2-base'
import weekData from 'cldr-data/supplemental/weekData.json'
import numbers from 'cldr-data/main/hu/numbers.json'
import timeZoneNames from 'cldr-data/main/hu/timeZoneNames.json'
import calendar from 'cldr-data/main/hu/ca-gregorian.json'
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json'

loadCldr(weekData, numbers, timeZoneNames, calendar, numberingSystems)

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {}
