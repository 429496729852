import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {Injectable, EventEmitter} from '@angular/core';
import * as firebase from 'firebase';
import {Observable, of} from 'rxjs';
import {AppUser} from '../models/app-user.model';
import {UserService} from './user.service';
import {User} from 'firebase';
import {RouteNames} from 'app/const/route/route-names.model';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userLoggedinEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  user$: Observable<firebase.User>;
  appUser: AppUser;

  constructor(
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.user$ = afAuth.authState;
    firebase.auth().onAuthStateChanged((user: User) => {
      if (user) {
        this.updateAppUser(user);
        this.router.navigate(['/' + RouteNames.PATH_EVENT]);
      } else {
        this.appUser = null;
        this.userLoggedinEvent.emit(false);
      }
      this.router.navigate(['/' + RouteNames.PATH_LOGIN]);
    });
  }

  private updateAppUser(user: firebase.User) {
    this.userService.get(user.uid).subscribe((appUser: AppUser) => {
      this.appUser = appUser;
      this.userLoggedinEvent.emit(true);
    });
  }

  login() {
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    localStorage.setItem('returnUrl', returnUrl);
  }

  isLoggedIn(): boolean {
    return this.appUser ? true : false;
  }

  signInWithGoogle() {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        let credential = result.credential;

        // The signed-in user info.
        let user = result.user;
        // ...
      })
      .catch(error => {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        // The email of the user's account used.
        let email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        let credential = error.credential;
        // ...
      });
  }

  logout() {
    this.afAuth.signOut();
    this.router.navigate(['/login']);
  }

  getCurrentUser(): AppUser {
    return this.appUser;
  }

  get appUser$(): Observable<AppUser> {
    return this.user$.pipe(
      switchMap((user: firebase.User) => {
        if (user) {
          return this.userService.get(user.uid);
        }
        return of(null);
      }),
    );
  }
}
